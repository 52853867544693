<template>
  <MiscellaneousReportField dialog-name="Report Fields" />
</template>
<script>
import MiscellaneousReportField from '@/views/app/miscellaneous/ReportField'

export default {
  name: 'ReportFieldWrapper',
  components: {
    MiscellaneousReportField
  }
}
</script>
